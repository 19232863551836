/** @jsx jsx */
import { jsx, Flex, Heading, Box, Image, Text, Button } from 'theme-ui'
import { FC, Fragment } from 'react'
import { Maybe, ProductItemType, ProductVariantType } from '~/@types/models'
import { Link } from 'gatsby'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import { imageClassByType } from '~/utils/product'
import ImageWrapper from '../image-wrapper'
import { openInquireForm } from '../header/bespoke-header/inquire-form'

type Props = {
  variant?: string
  product: ProductItemType
  visibleVariantsCount?: number
  productVariant?: Maybe<ProductVariantType>
  collectionHandle: string
  onItemClick?: () => void
  template?: 'default' | 'bespoke'
  noLink?: boolean
}

type UseProductLinkType = {
  product: ProductItemType
  collectionHandle: string
}

const useProductLink = ({ product, collectionHandle }: UseProductLinkType) => {
  let linkProps = {}

  if (collectionHandle && collectionHandle.indexOf('sale') >= 0) {
    linkProps = {
      ...linkProps,
      state: {
        comesFromSaleCollections: true,
      },
    }
  }

  const productLink = `${urls.pages.productDetail}/${product.handle}`

  return {
    linkProps,
    productLink,
  }
}

const AntiqueRugsProductItem: FC<Props> = ({
  variant = 'primary',
  product,
  collectionHandle,
  onItemClick,
  template = 'default',
  noLink = false,
}) => {
  const { linkProps, productLink } = useProductLink({
    product,
    collectionHandle,
  })

  const _productLink = template !== 'default' ? `/${template}${productLink}` : productLink
  const imageIndex = template === 'default' ? 1 : 0
  const isRugs = productLink?.indexOf('rugs') >= 0 || product.title?.indexOf('AC') >= 0

  const collectionImageSource =
    product.images.length > 1
      ? product?.images[imageIndex]?.srcMobileSmall
      : product?.mainImage?.srcMobileSmall

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
      }}
      onContextMenu={() => onItemClick && onItemClick()}
      onClick={() => onItemClick && onItemClick()}
    >
      {product.mainImage && (
        <Link
          className="product-image"
          to={noLink ? '' : _productLink}
          {...linkProps}
          sx={{
            maxHeight: 'calc(100% - 48px)',
            overflow: 'hidden',
            position: 'relative',
            '&:hover': {
              '.product-image-button': {
                transform: 'translateY(0)',
              },
            },
          }}
        >
          <ImageWrapper ratio={404 / 541} center>
            <Image
              className={`${imageClassByType(product)} ${product.handle}`}
              src={collectionImageSource}
              alt={product.mainImage.altText || product.title || 'Product Image'}
              onMouseOver={(e: { currentTarget: { src: string } }) => {
                e.currentTarget.src = product?.mainImage?.srcMobileSmall
              }}
              onMouseOut={(e: { currentTarget: { src: string } }) => {
                e.currentTarget.src = collectionImageSource
              }}
              sx={{
                zIndex: 1,
                width: '100%',
                height: '100%',
                objectFit: isRugs ? 'cover' : 'contain',
              }}
            />
          </ImageWrapper>
          {template === 'bespoke' && (
            <Box
              className="product-image-button"
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 10,
                width: '100%',
                padding: '7px',
                transform: 'translateY(100%)',
                transition: 'transform 0.3s ease-in-out',
                backgroundColor: 'rgba(255, 255, 255, 0.78)',
              }}
            >
              <Button
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  openInquireForm({
                    product,
                  })
                }}
                sx={{
                  border: '1px solid #16283E',
                  background: 'transparent',
                  color: '#16283E',
                  padding: '10px 20px',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  fontFamily: 'var(--font-family-sans-serif)',
                }}
              >
                Inquire
              </Button>
            </Box>
          )}
        </Link>
      )}
      <Flex
        className="product-item-content"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          px: '10px',
          flexDirection: ['column', 'row'],
          mt: 11,
        }}
      >
        <Box
          sx={{
            textAlign: ['center', 'left'],
            width: ['100%', 'initial'],
          }}
        >
          <Link
            to={productLink}
            sx={{
              textDecoration: 'none',
            }}
            {...linkProps}
          >
            <Heading
              as="h3"
              variant="title7"
              sx={{
                variant: `variants.product.${variant}.title`,
                textTransform: 'uppercase',
                fontSize: template === 'bespoke' ? ['18px', '20px'] : 'initial',
                fontFamily:
                  template !== 'bespoke'
                    ? 'var(--font-family-serif)'
                    : 'var(--font-family-sans-serif)',
              }}
            >
              {/* {product.metafields.antiqueName}, {product.metafields.origin?.[0]},{' '}
              {product.metafields.filterSize?.[0]} */}
              {product.title}
            </Heading>
            <Text
              sx={{
                fontSize: '11px',
                fontFamily: 'helvetica',
                fontWeight: '200',
                color: '#595858',
              }}
            >
              {product.cheapestVariant?.sku}
            </Text>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AntiqueRugsProductItem
